import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
//import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
//import StopIcon from '@material-ui/icons/ArrowDropDown'
import StopIcon from "@material-ui/icons/ArrowRight";
//import StopIcon from '@material-ui/icons/Stop';

import { URLS } from "../config/config";
import { selectTab } from "../actions/index";
//import MenuDropDown from "./Menu";

const TabsConfig = URLS();

const HeaderTab = (props) => {
  //React hook (must be called inside component)
  //const classes = useStyles();
  //array destructuring. useState is a primitive hook returns an array with two entries. The argument is the initial value.
  //value is the piece of state we keep track of. setValue is a setter function to update state
  //const [value, setValue] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

/*   const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  }; */

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleChange = (e, value) => {
    props.selectTab(value);
    };

  return (
    //The link component changes the URL (eventEmitter)
    // {config().headerTabs.map((item) => <Tab className={item.styleClass} component={Link} to={item.path} label={item.label}></Tab>)}
    <React.Fragment>
      <Tabs
        className={props.classes.tabContainer}
        value={props.tab === 4 ? false : props.tab}
        onChange={handleChange}
      >
        <Tab
          className={props.classes.tab}
          classes={{ root: props.classes.rootTab}}
          component={Link}
          to={TabsConfig.headerTabs[0].path}
          label={
            <div textalign="center">
              <StopIcon className={props.classes.stopIcon}></StopIcon>
              <span verticalalign="middle">
                {TabsConfig.headerTabs[0].label}
              </span>
            </div>
          }
        ></Tab>
        {/* <Tab
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup={anchorEl ? true : undefined}
          classes={{ root: props.classes.rootTab}}
          className={props.classes.tab}
          component={Link}
          to={TabsConfig.headerTabs[1].path}
          label={
            <div textalign="center">
              {open ? <ArrowDropDownIcon className={props.classes.stopIcon}></ArrowDropDownIcon> : <StopIcon className={props.classes.stopIcon}></StopIcon> }
              <span verticalalign="middle">
                {TabsConfig.headerTabs[1].label}
              </span>
            </div>
          }
          onClick={(e) => handleClick(e)}
          onMouseOver={(e) => handleClick(e)}
        ></Tab> */}
        {/* <Tab
          classes={{ root: props.classes.rootTab}}
          className={props.classes.tab}
          component={Link}
          to={TabsConfig.headerTabs[2].path}
          label={
            <div textalign="center">
              <StopIcon className={props.classes.stopIcon}></StopIcon>
              <span verticalalign="middle">
                {TabsConfig.headerTabs[2].label}
              </span>
            </div>
          }
        ></Tab> */}
        <Tab
          classes={{ root: props.classes.rootTab}}
          className={props.classes.tab}
          component={Link}
          to={TabsConfig.headerTabs[3].path}
          label={
            <div textalign="center">
              <StopIcon className={props.classes.stopIcon}></StopIcon>
              <span verticalalign="middle">
                {TabsConfig.headerTabs[3].label}
              </span>
            </div>
          }
        ></Tab>
      </Tabs>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: props.classes.menu }}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
      >
        {/* <MenuItem
          classes={{ root: props.classes.menuItem }}
          onClick={() => {
            handleClose();
            props.selectTab(1);
          }}
          component={Link}
          to="/services"
        >
          <div textalign="center">
            <StopIcon className={props.classes.stopIcon}></StopIcon>
            <span verticalalign="middle">Services</span>
          </div>
        </MenuItem> */}
        {/* <MenuItem
          classes={{ root: props.classes.menuItem }}
          onClick={() => {
            handleClose();
            props.selectTab(1);
          }}
          component={Link}
          to="/customsoftware"
        >
        </MenuItem> */}
        {/* <MenuItem
          classes={{ root: props.classes.menuItem }}
          onClick={() => {
            handleClose();
            props.selectTab(1);
          }}
          component={Link}
          to="/mobileappdev"
        >
          Mobile App Dev
        </MenuItem> */}
        {/* <MenuItem
          classes={{ root: props.classes.menuItem }}
          onClick={() => {
            handleClose();
            props.selectTab(1);
          }}
          component={Link}
          to="/websitedev"
        >
          Website Dev
        </MenuItem> */}
      </Menu>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { tab: state.tab };
};

export default connect(mapStateToProps, {
  selectTab,
})(HeaderTab);
