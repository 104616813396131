import {
  Scene,
  WebGLRenderer,
  PerspectiveCamera,
  DirectionalLight,
  AmbientLight,
  MeshStandardMaterial,
  Mesh,
  Raycaster,
  Vector2,
} from "three";

import { isMobile } from "react-device-detect";

import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { DRACO_DECODER_PATH, RENDER_ID } from "./config";

import BallSpinLoader from "./BallSpinnerLoader";

let touchRunning = false;

const createThreeScene = (canvasId, props, actionCreators) => {
  const drcFilename = props.presignedUrl;
  const meshName = props.itemId;
  
  let camera;
  let scene;
  let raycaster;
  let renderer;
  let controls;
  let spinLoader;
  let mouse;
  let light1;
  let light2;

  const ROTATION_INCREMENT = 0.1;

  const init = () => {

    const domRect = document.getElementById(canvasId).getBoundingClientRect();
    const width = domRect.right - domRect.left;
    const height = domRect.bottom - domRect.top;
    
    let initScale = 0.1;
    if (props.hasOwnProperty("scene")) {
      initScale = props.scene.scale ? props.scene.scale : 0.1;
    }

    let initRotateX = 0;
    let initRotateY = 0;
    let initRotateZ = 0;

    if (props.hasOwnProperty("scene")) {
      initRotateX = props.scene.rotateX ? props.scene.rotateX : 0.0;
      initRotateY = props.scene.rotateY ? props.scene.rotateY : 0.0;
      initRotateZ = props.scene.rotateZ ? props.scene.rotateZ : 0.0;
    }

    renderer = new WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x000000, 0.9);
    renderer.setSize(width, height);
    renderer.domElement.id = RENDER_ID;
    document.getElementById(canvasId).appendChild(renderer.domElement);
    
    //Create copyright text
    const div_element = document.createElement("div");
    const a_element = document.createElement("a");
    
    if (props.attribution_link !== "") {
      a_element.innerHTML = props.attribution;
      a_element.href = props.attribution_link;
      div_element.appendChild(a_element);
    } else {
      div_element.innerHTML = props.attribution;
    }

    document.getElementById("Credit").innerHTML = "";
    document.getElementById("Credit").appendChild(div_element);

    scene = new Scene();

    camera = new PerspectiveCamera(75, width / height, 0.1, 10000);
    camera.position.set(0, 0, 300);
    scene.add(camera);

    const ambientLight = new AmbientLight(0x404040);
    scene.add(ambientLight);

    light1 = new DirectionalLight(0xffffff, 1.5);
    light2 = new DirectionalLight(0xffffff, 1.5);
    light1.position.set(100, 100, 100);
    light2.position.set(-100, 100, 100);
    scene.add(light1);
    scene.add(light2);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.update();
    controls.enabled = false;

    spinLoader = new BallSpinLoader({ groupRadius: 20 });
    spinLoader.setPosition(0, 0, -10);
    spinLoader.addToScene(scene);

    mouse = new Vector2(0.1, 0.1);
    raycaster = new Raycaster();

    if (!isMobile) window.addEventListener("resize", onWindowResize);

    let drcLoader = new DRACOLoader();
    drcLoader.setDecoderPath(DRACO_DECODER_PATH);
    drcLoader.preload();

    drcLoader.load(drcFilename, (geometry) => {
      renderer.setClearColor(0xffffff, 0.9);
      geometry.computeVertexNormals();
      scene.getObjectByName("Spinner").visible = false;
      const material = new MeshStandardMaterial({ color: 0x606060 });
      const mesh = new Mesh(geometry, material);
      mesh.name = meshName;
      scene.add(mesh);
      scene.getObjectByName(meshName).visible = true;
      mesh.scale.set(initScale, initScale, initScale);
      mesh.position.set(1, 1, -10);
      mesh.rotateX(initRotateX);
      mesh.rotateY(initRotateY);
      mesh.rotateZ(initRotateZ);

      light1.target = mesh;
      light2.target = mesh;

      camera.position.setZ(20);
      camera.updateProjectionMatrix();
      controls.enabled = true;

      if (!isMobile) document.getElementById(RENDER_ID).addEventListener("dblclick", onDoubleClick, false);
      //if (isMobile) document.getElementById(RENDER_ID).addEventListener("touchend", onDoubleTouch, false);
      if (isMobile) document.getElementById(RENDER_ID).addEventListener("touchstart", onTripleTouch, false);
      document.onkeydown = checkKey;
      drcLoader.dispose();
    });
  };

  const update = () => {
    spinLoader.animate();
    renderer.render(scene, camera);
    requestAnimationFrame(update);
  };

  const onWindowResize = () => {
    if (document.getElementById(RENDER_ID) !== null) {
    const domRect = document.getElementById(RENDER_ID).getBoundingClientRect();
    const width = domRect.right - domRect.left;
    const height = domRect.bottom - domRect.top;
    renderer.setViewport(0, 0, width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    }
  };

  const onDoubleClick = (e) => {
    const domRect = document.getElementById(RENDER_ID).getBoundingClientRect();

    mouse.x = ((e.clientX - domRect.left) / (domRect.right - domRect.left)) * 2 - 1;
    mouse.y = -((e.clientY - domRect.top) / (domRect.bottom - domRect.top))  * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    let intersects = raycaster.intersectObject(
      scene.getObjectByName(meshName),
      false
    );

    if (intersects.length > 0) {
      intersects[0].object.material.color.setHex(0x0080FF);
      actionCreators.ItemSelect(intersects[0].object.name);
    } else {
      scene.getObjectByName(meshName).material.color.setHex(0x606060);
      actionCreators.ItemSelect(null);
    }
  };

  // const onDoubleTouch = (e) => {
  //   if (e.targetTouches.length === 1 && !touchRunning) {
  //     setTimeout( () => { touchRunning = false}, 200);
  //   } 
  //   if (e.targetTouches.length === 1 && touchRunning) {
      
  //     const domRect = document.getElementById(RENDER_ID).getBoundingClientRect();

  //     mouse.x = ((e.targetTouches.item(0).clientX - domRect.left) / (domRect.right - domRect.left)) * 2 - 1;
  //     mouse.y = -((e.targetTouches.item(0).clientY - domRect.top) / (domRect.bottom - domRect.top))  * 2 + 1;

  //     raycaster.setFromCamera(mouse, camera);

  //     let intersects = raycaster.intersectObject(
  //       scene.getObjectByName(meshName),
  //       false
  //     );

  //     if (intersects.length > 0) {
  //       intersects[0].object.material.color.setHex(0x0080FF);
  //       actionCreators.ItemSelect(intersects[0].object.name);
  //     } else {
  //       scene.getObjectByName(meshName).material.color.setHex(0x606060);
  //       actionCreators.ItemSelect(null);
  //     } 
  //     touchRunning = false;
  //   }
  // };

  const onTripleTouch = (e) => {
    
    if (e.targetTouches.length === 3) {
      
      const domRect = document.getElementById(RENDER_ID).getBoundingClientRect();

      mouse.x = ((e.targetTouches.item(0).clientX - domRect.left) / (domRect.right - domRect.left)) * 2 - 1;
      mouse.y = -((e.targetTouches.item(0).clientY - domRect.top) / (domRect.bottom - domRect.top))  * 2 + 1;

      raycaster.setFromCamera(mouse, camera);

      let intersects = raycaster.intersectObject(
        scene.getObjectByName(meshName),
        false
      );

      if (intersects.length > 0) {
        intersects[0].object.material.color.setHex(0x0080FF);
        actionCreators.ItemSelect(intersects[0].object.name);
      } else {
        scene.getObjectByName(meshName).material.color.setHex(0x606060);
        actionCreators.ItemSelect(null);
      } 
    }
  };

  const animationBuilder = (direction) => {
    //based on key pressed, rotate +-x or +-y

    switch (direction) {
      case "up":
        scene.getObjectByName(meshName).rotation.x -= ROTATION_INCREMENT;
        break;
      case "down":
        scene.getObjectByName(meshName).rotation.x += ROTATION_INCREMENT;
        break;
      case "left":
        scene.getObjectByName(meshName).rotation.y -= ROTATION_INCREMENT;
        break;
      case "right":
        scene.getObjectByName(meshName).rotation.y += ROTATION_INCREMENT;
        break;
      default:
        break;
    }
  };

  //callback function for key press event listener
  const checkKey = (e) => {
    e = e || window.event;
    //console.log(e.target.id);
    if (e.target.id !== "body") {
      return;
    }

    e.preventDefault();

    //based on keycode, trigger appropriate animation
    if (e.keyCode === 38) {
      animationBuilder("up");
    } else if (e.keyCode === 40) {
      animationBuilder("down");
    } else if (e.keyCode === 37) {
      animationBuilder("left");
    } else if (e.keyCode === 39) {
      animationBuilder("right");
    }
  };

  if (document.getElementById(canvasId)) {
    if (document.getElementById(RENDER_ID)) {
      document.getElementById(RENDER_ID).remove();
      touchRunning = false;
    }
    init();
    update();
  }
};

export default createThreeScene;
