import { FETCH_DATA_SUPPLY } from "../config/config";

const fetchDataSupplyReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_DATA_SUPPLY:
      return action.payload;
    default:
      return state;
  }
};

export default fetchDataSupplyReducer;
