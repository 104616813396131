import React from "react";
import { connect } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import {itemSelect} from "../actions/index";
import createThreeScene from "../config/threeScene";

const DataViewer = (props) => {
  console.log("dataviever triggered")
  // if (props.fetchData !== {}) {
  //   createThreeScene("Canvas", props.fetchData, { "ItemSelect": props.itemSelect});  
  // }
  if (Object.keys(props.fetchData).length !== 0) {
    createThreeScene("Canvas", props.fetchData, { "ItemSelect": props.itemSelect});  
  }
  
  return (
    <React.Fragment>
      <CssBaseline />
      <Container disableGutters maxWidth="xl">
        <Typography
          component="div"
          id="Canvas"
          style={{ backgroundColor: "#FFFFFF", height: "75vh" }}
        />
      </Container>
      <div id="Credit"></div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { fetchData: state.fetchData, windowSize: state.windowResize };
};

export default connect(mapStateToProps, {itemSelect})(DataViewer);

