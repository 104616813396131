import React from "react";

//import useStyles from "../theme_styles/styles";

const Footer = () => {

//const classes = useStyles();

  return <div></div>;
};

export default Footer;
