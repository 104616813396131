import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
//import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import HeaderTabs from "./HeaderTabs";
//import HeaderButton from "./HeaderButton";
import HeaderSearchButton from "./HeaderSearchBar";
import HeaderDrawer from "./Drawer";
import HeaderHomeButton from "./HeaderHomeButton";
import useStyles from "../theme_styles/styles";
 

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Header(props) {
  //React hook (must be called inside component)
  const classes = useStyles();
  const theme = useTheme();
  const belowMdScreenSize = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" color="primary" className={classes.appbar}>
          <Toolbar disableGutters={true} className={classes.toolbar}>
            <HeaderHomeButton classes={classes}></HeaderHomeButton>
            <HeaderSearchButton classes={classes}></HeaderSearchButton>
            {belowMdScreenSize ? (
              <HeaderDrawer classes={classes}></HeaderDrawer>
            ) : (
              <HeaderTabs classes={classes}></HeaderTabs>
            )}
            {belowMdScreenSize ? null : (
              null
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin}></div>
    </React.Fragment>
  );
}
