import { SCREEN_RESIZE } from "../config/config";

const initialState = {
    screenWidth: typeof window === 'object' ? window.innerWidth : null
};

const windowResizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCREEN_RESIZE:
      return action.payload;
    default:
      return state;
  }
};

export default windowResizeReducer;
