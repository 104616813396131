import { FETCH_DATA } from "../config/config";

const fetchDataReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default fetchDataReducer;
