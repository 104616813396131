import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//import logo from "../logo.svg";
//import "../App.css";
import { ThemeProvider } from "@material-ui/core/styles";

import Header from "../components/Header";
import theme from "../theme_styles/themes";

import GridHome from "../components/GridHome";
import Footer from "../components/Footer";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={GridHome}></Route>
          <Route
            exact
            path="/services"
            component={() => <div>Services</div>}
          ></Route>
          <Route
            exact
            path="/customsoftware"
            component={() => <div>Custom Software</div>}
          ></Route>
          <Route
            exact
            path="/about"
            component={() => (
              <div style={{ fontFamily: "Arial" }}>
                <div style={{ marginLeft: "20px", marginTop: "25px" }}>
                  <br></br>
                  <h3>This application uses the following stack:</h3>
                  <br></br>
                  <h4>Frontend</h4>
                  <ul style={{ lineHeight: "50%" }}>
                    <li>
                      <h5>ReactJS with Redux state container</h5>
                    </li>
                    <li>
                      <h5>MaterialUI</h5>
                    </li>
                    <li>
                      <h5>THREE.JS</h5>
                    </li>
                  </ul>
                  <br></br>
                  <h4>Backend</h4>
                  <ul style={{ lineHeight: "50%" }}>
                    <li>
                      <h5>Node.js with Express framework</h5>
                    </li>
                    <li>
                      <h5>MongoDB</h5>
                    </li>
                    <li>
                      <h5>Amazon S3 bucket</h5>
                    </li>
                  </ul>
                  <br></br>
                  <h4>The 3D objects were compressed with the Draco library</h4>
                  <br></br>
                  <br></br>
                  <h6>Created by NBH | 2022</h6>
                </div>
              </div>
            )}
          ></Route>
          <Route
            exact
            path="/contact"
            component={() => <div>Contact</div>}
          ></Route>
          <Route
            exact
            path="/estimate"
            component={() => (
              <div style={{ fontFamily: "Arial" }}>
                <div style={{ marginLeft: "20px", marginTop: "25px" }}>
                  <br></br>
                  <h3>How to use this application:</h3>
                  <br></br>
                  <h4>Enter one of the following case-sentive search terms:</h4>
                  <h5>
                    Axle, Carburetor, Cylinder, Engine, Manifold, Wheel,
                    Compressor, or Crankshaft
                  </h5>
                  <br></br>
                  <h4>Manipulation of 3D-model: </h4>
                  <ul style={{ lineHeight: "50%" }}>
                    <li>
                      <h5>Item can be rotated and dragged</h5>
                    </li>
                    <li>
                      <h5>Zoom by pinching</h5>
                    </li>
                    <li>
                      <h5>Double tab on model to see available suppliers</h5>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          ></Route>
        </Switch>
        <Footer></Footer>
      </BrowserRouter>
    </ThemeProvider>
  );
}
