import { makeStyles, fade } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  //a
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
  //b
  buttonHeader: {
    ...theme.typography.estimateButton,
    borderRadius: "50px",
    marginLeft: "300px",
    marginRight: "250px",
    height: "44px",
    width: "150px",
    [theme.breakpoints.down("lg")] : {
      marginRight: "50px",
      marginLeft: "200px"
    }
  },
  //c
  container: {
    maxHeight: 440,
  },
  //d
  drawer: {
    backgroundColor: theme.palette.common.blue,
  },
  drawerIcon: {
    height: "50px",
    width: "50px"
  },
  drawerIconContainer: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  drawerItem: {
    ...theme.typography.tabs,
    color: "white",
  },
  drawerItemEstimate: {
    backgroundColor: theme.palette.common.orange,
  },
  //e-g
  grow: {
    flexGrow: 1,
  },
  //h
  homeButton: {
    "&:hover" : {
      backgroundColor: theme.palette.common.blue
    }
  },
  //i
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
      "&:focus": {
        width: "40ch",
      },
    },
  },
  inputRoot: {
    color: "inherit",
  },
  //j-l
  logo: {
    height: "3.5rem",
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "45px",
    marginRight: "75px",
    [theme.breakpoints.down("md")] : {
     marginRight: "10px",
     height: "3.5rem", 
    },
    [theme.breakpoints.down("xs")] : {
      marginLeft: "10px",
      marginRight: "15px",
      height: "3rem",
    }
  },
  //m
  menu: {
    marginTop: "0px",
    anchorPosition: {
      vertical: "top",
      horizontal: "center",
    },
    backgroundColor: theme.palette.common.blue,
    color: "white",
  },
  menuItem: {
    ...theme.typography.tabs,
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
    verticalAlign: "top"
  },
  //n-r
  root: {
    width: "100%"
  },
  rootTab: {
    paddingLeft: "0px",
  }, 
  //s
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "0px",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  stopIcon: {
    verticalAlign: "middle",
    marginBottom: "2px",
    marginLeft: "0px",
    marginRight: "10px",
    height: "1.5rem",
  },
  //t
  tab: {
    ...theme.typography.tabs,
    minWidth: 10,
    marginLeft: "30px",
    "&:hover" : {
      color: "#dddddd"
    }
  },
  tabContainer: {
    marginLeft: "auto",
    marginRight: "200px",
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "30px",
  },
}));
