import axios from "axios";
import { isMobile } from "react-device-detect";

let url;

if (process.env.NODE_ENV === "production") {
  url = process.env.baseURL;
} else {
  url = isMobile ? "http://192.168.0.127:5000" : "http://localhost:5000";
}
export default axios.create({
  baseURL: url,
});
