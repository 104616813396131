import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";

import { URLS } from "../config/config";
import { selectTab } from "../actions/index";
import logo from "../assets/gears.svg";

const TabsConfig = URLS();

const HeaderHomeButton = (props) => {
  return (
    <IconButton
      onClick={() => props.selectTab(0)}
      className={props.classes.homeButton}
      component={Link}
      to={TabsConfig.headerTabs[0].path}
      disableRipple
    >
      <img alt="company logo" src={logo} className={props.classes.logo} />
    </IconButton>
  );
};

export default connect(null, { selectTab })(HeaderHomeButton);
