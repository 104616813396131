import React from "react";
import Grid from "@material-ui/core/Grid";

import DataViewer from "./DataViewer";
import TableViewer from "./TableViewer";
import useStyles from "../theme_styles/styles";

const GridHome = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid wrap="wrap-reverse" justify="space-around" container direction="row">
          <Grid item xs={12} sm={12} md={false} lg={4} xl={4}>
            <TableViewer classes={classes}></TableViewer>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
            <DataViewer></DataViewer>
          </Grid>
          <Grid item xs={false} sm={false} md={false} lg={1} xl={1}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GridHome;
