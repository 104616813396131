import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

//import "./index.css";
import App from "./components/App";
//import reportWebVitals from "./reportWebVitals";
import reducers from "./reducers";
import { URL_SELECT, TAB_SELECT, URLS } from "./config/config";
import { screenResize } from "./actions/index";

const store = createStore(reducers, applyMiddleware(thunk));

const TabConfig = URLS();

function URLlistener(dispatch, getState) {
  const currentTab = getState.tab;

  let tab = null;
  switch (window.location.pathname) {
    case TabConfig.headerTabs[0].path:
      currentTab !== 0 ? (tab = 0) : (tab = null);
      break;
    case TabConfig.headerTabs[1].path:
      currentTab !== 1 ? (tab = 1) : (tab = null);
      break;
    case TabConfig.headerTabs[2].path:
      currentTab !== 2 ? (tab = 2) : (tab = null);
      break;
    case TabConfig.headerTabs[3].path:
      currentTab !== 3 ? (tab = 3) : (tab = null);
      break;
    default:
      tab = null;
  }

  dispatch({ type: URL_SELECT, payload: tab });
  dispatch({ type: TAB_SELECT, payload: tab });
}

window.addEventListener(
  "popstate",
  URLlistener(store.dispatch, store.getState)
);

window.addEventListener('resize', () => {
  store.dispatch(screenResize(window.innerWidth));
});

ReactDOM.render(
  <Provider store={store}>
    {TabConfig.useReactStrictMode ? <React.StrictMode><App /></React.StrictMode> : <App />}
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
