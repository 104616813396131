import { SEARCHBAR_CHANGE } from "../config/config";

const searchBarReducer = (state = "Search...", action) => {
  switch (action.type) {
    case SEARCHBAR_CHANGE:
      return action.payload;
    default:
      return state;
  }
};

export default searchBarReducer;
