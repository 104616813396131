import React from "react";
import { connect } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const columns = [
  {
    id: "name",
    label: "Company name",
    minWidth: 200,
  },
  {
    id: "adresss",
    label: "Address",
    minWidth: 200,
  },
  {
    id: "country",
    label: "Country",
    minWidth: 200,
  },
];

const TableViewer = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={props.classes.root}>
      <TableContainer className={props.classes.container}>
        <Table stickyHeader aria-label="sticky table">
        {props.fetchDataSupply.length > 0 ?
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead> : null}
          {props.fetchDataSupply.length > 0 ? 
          <TableBody>
            {props.fetchDataSupply.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody> : null }
        </Table>
      </TableContainer>
      {props.fetchDataSupply.length > 0 ?
      <TablePagination
        rowsPerPageOptions={[1, 5, 10]}
        component="div"
        count={props.fetchDataSupply.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      ></TablePagination> : null }
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return { fetchDataSupply: state.fetchDataSupply };
};

export default connect(mapStateToProps)(TableViewer);
