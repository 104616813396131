import { TAB_SELECT } from "../config/config";
import { SEARCHBAR_CHANGE } from "../config/config";
import { FETCH_DATA } from "../config/config";
import { FETCH_DATA_SUPPLY } from "../config/config";
import { SCREEN_RESIZE } from "../config/config";
//import jsonPlaceholder from "../apis/jsonPlaceholder";
import server from "../apis/server";

const itemCache = [];
const supplierCache = [];

export const selectTab = (tab) => {
  return (dispatch) => {
    if (tab !== 0) dispatch({ type: FETCH_DATA_SUPPLY, payload: [] });
    dispatch({ type: TAB_SELECT, payload: tab });
  };
};

export const changeSearchBar = (searchTerm) => {
  return {
    type: SEARCHBAR_CHANGE,
    payload: searchTerm,
  };
};

export const screenResize = (width) => {
  return {
    type: SCREEN_RESIZE,
    payload: width,
  };
};

export const submitSearchBar = (searchTerm) => {
  return async (dispatch) => {
    let error_code = 0;
    const response = await server.get(`/item/${searchTerm}`).catch( (err) => {error_code = err.response.status});
    if (error_code === 0) { 
      dispatch({
        type: FETCH_DATA,
        payload: response.data,
      });
    }
  }
};

export const itemSelect = (itemId) => {
  if (itemId === null) {
    return {
      type: FETCH_DATA_SUPPLY,
      payload: [],
    };
  }
  if (itemCache.find((item) => item === itemId)) {
    const index = itemCache.indexOf(itemId);
    return {
      type: FETCH_DATA_SUPPLY,
      payload: supplierCache[index],
    };
  }

  return async (dispatch) => {
    const response = await server.get(`/suppliers/${itemId}`);
    const array = response.data.map((item) => item.final[0]);
    supplierCache.push(array);
    dispatch({
      type: FETCH_DATA_SUPPLY,
      payload: array,
    });
  };
};
