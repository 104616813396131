import React from "react";
import { connect } from "react-redux";

import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { changeSearchBar, submitSearchBar } from "../actions/index";

let helpMessage = "Enter one of the following search terms (case sensitive): Axle, Carburetor, Cylinder, Engine, Manifold, Wheel, Compressor, or Crankshaft. ";
helpMessage += "The 3D-model can be rotated with the arrow keys or by holding down the left mouse button. Use scroll to zoom. Pan by holding down right mouse button. "
helpMessage += "Double click to highlight model and see available vendors."

const SearchBar = (props) => {
  
  const handleChange = (e) => {
    props.changeSearchBar(e.target.value)
  };

  const handleBlur = () => {
    props.changeSearchBar("Search...")
    document.activeElement.blur()
  }

  const handleFocus = () => {
    props.changeSearchBar("")
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    props.submitSearchBar(props.search)
    handleBlur();
  };

  return (
    <React.Fragment>
        {window.innerWidth > 500 ? <div borderright="20px"><Tooltip disableTouchListener="false" title = {helpMessage}>
          <HelpOutlineIcon color={props.tab === 0 ? "inherit" : "disabled"} />
      </Tooltip>
      </div> : null }
        <div className={props.classes.search}>
         <div className={props.classes.searchIcon}>
          <SearchIcon 
          color={props.tab === 0 ? "inherit" : "disabled"}
          />
        </div>
        <form onSubmit={handleSubmit}>
        <InputBase 
          disabled ={props.tab !== 0}
          id="SearchBar"
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={props.search}
          placeholder="Carburetor"
          classes={{
            root: props.classes.inputRoot,
            input: props.classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        /></form>
      </div>
      <div className={props.classes.grow} />
      <div className={props.classes.sectionDesktop}></div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { search: state.searchTerm, tab: state.tab };
};

export default connect(mapStateToProps, { changeSearchBar, submitSearchBar })(SearchBar);
