import React, { useState } from "react";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import HeaderList from "./List";

const HeaderDrawer = (props) => {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: props.classes.drawer }}
      >
        <div className={props.classes.toolbarMargin}></div>
        <HeaderList classes={props.classes} hook={setOpenDrawer}></HeaderList>
      </SwipeableDrawer>
      <IconButton
        className={props.classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon className={props.classes.drawerIcon}></MenuIcon>
      </IconButton>
    </React.Fragment>
  );
};

export default HeaderDrawer;
