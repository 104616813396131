import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { URLS } from "../config/config";
import { selectTab } from "../actions/index";

const TabsConfig = URLS();

const HeaderList = (props) => {
  return (
    <List disablePadding>
      <ListItem
        onClick={() => {props.hook(false); props.selectTab(0)}}
        divider
        button
        component={Link}

        to={TabsConfig.headerTabs[0].path}
        selected={props.tab === 0}
      >
        <ListItemText className={props.classes.drawerItem}>
          {TabsConfig.headerTabs[0].label}
        </ListItemText>
      </ListItem>
      {/* <ListItem
        onClick={() => {props.hook(false); props.selectTab(1)}}
        divider
        button
        component={Link}
        to={TabsConfig.headerTabs[1].path}
        selected={props.tab === 1}
      >
        <ListItemText className={props.classes.drawerItem}>
          {TabsConfig.headerTabs[1].label}
        </ListItemText>
      </ListItem> */}
      {/* <ListItem
        onClick={() => {props.hook(false); props.selectTab(2)}}
        divider
        button
        component={Link}
        to={TabsConfig.headerTabs[2].path}
        selected={props.tab === 2}
      >
        <ListItemText className={props.classes.drawerItem}>
          {TabsConfig.headerTabs[2].label}
        </ListItemText>
      </ListItem> */}
      <ListItem
        onClick={() => {props.hook(false); props.selectTab(3)}}
        divider
        button
        component={Link}
        to={TabsConfig.headerTabs[3].path}
        selected={props.tab === 3}
      >
        <ListItemText className={props.classes.drawerItem}>
          {TabsConfig.headerTabs[3].label}
        </ListItemText>
      </ListItem>
      <ListItem
        onClick={() => {props.hook(false); props.selectTab(4)}}
        divider
        button
        component={Link}
        to={TabsConfig.headerButtons[0].path}
        className={props.classes.drawerItemEstimate}
        selected={props.tab === 4}
      >
        <ListItemText className={props.classes.drawerItem}>
          {TabsConfig.headerButtons[0].label}
        </ListItemText>
      </ListItem>
    </List>
  );
};

const mapStateToProps = (state) => {
    return { tab: state.tab };
  };

export default connect(mapStateToProps, {selectTab})(HeaderList);
