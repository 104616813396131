import { combineReducers } from "redux";

import selectedTabReducer from "./selectedTabReducer";
import selectedURLReducer from "./selectedURLReducer";
import searchBarReducer from "./searchBarReducer";
import fetchDataReducer from "./fetchDataReducer";
import fetchDataSupplyReducer from "./fetchDataSupplyReducer";
import windowResizeReducer from "./windowResizeReducer";

export default combineReducers({
  url: selectedURLReducer,
  tab: selectedTabReducer,
  searchTerm: searchBarReducer,
  fetchData: fetchDataReducer,
  fetchDataSupply: fetchDataSupplyReducer,
  windowResize: windowResizeReducer
});
