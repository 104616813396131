export const URLS = () => {
  return {
    headerTabs: [
      {
        label: "Home",
        path: "/",
      },
      {
        label: "Services",
        path: "/services",
      },
      {
        label: "Contact Us",
        path: "/contact",
      },
      {
        label: "Tech stack",
        path: "/about",
      },
    ],
    headerButtons: [
      {
        label: "Help",
        path: "/estimate",
      },
    ],
    useReactStrictMode: false
  };
}

export const DRACO_DECODER_PATH = "https://www.gstatic.com/draco/versioned/decoders/1.4.1/";
export const RENDER_ID = "RenderID"
export const TAB_SELECT = "TAB_SELECT";
export const URL_SELECT = "URL_SELECT";
export const SEARCHBAR_CHANGE = "SEARCHBAR_CHANGE";
export const FETCH_DATA = "FETCH_DATA";
export const FETCH_DATA_SUPPLY = "FETCH_DATA_SUPPLY";
export const SCREEN_RESIZE = "SCREEN_RESIZE";